import type { QueryFunctionContext } from '@tanstack/react-query'

import { fetchWithAzureToken } from 'azure/client/user'
import { AzureScopes } from 'config/auth'
import { RoutePaths } from 'config/routes'
import jsonOrEmptyTextMapper from 'utils/jsonOrEmptyTextMapper'

/**
 * @param {string} warehouseId - Warehouse ID for fetching stock
 * @param {string} itemNumber - Item number to fetch stock for
 * @returns stock details from azure
 */

type QueryKey = [queryFnName: string, warehouseId: string, itemNumber: string]

type StockList = Pick<QueryFunctionContext<QueryKey>, 'queryKey'>

async function getAzureStock({ queryKey: [, warehouseId, itemNumber] }: StockList): Promise<Stock> {
  if (!warehouseId || !itemNumber) {
    return Promise.reject(new Error('warehouseId or itemNumber is missing'))
  }
  const params = new URLSearchParams({ warehouseId, itemNumber }).toString()

  const response = await fetchWithAzureToken(AzureScopes.Orders, `${RoutePaths.stockApi}?${params}`)
  return await jsonOrEmptyTextMapper(response)
}

export default getAzureStock
