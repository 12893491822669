import { Box, Flex, ListItem, Stack, Text } from '@chakra-ui/react';
import type { Product, ProductProjection } from '@commercetools/platform-sdk';
import useTranslation from 'next-translate/useTranslation';
import React, { FunctionComponent, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { DT, DD } from 'components/elements/DescriptionListElements';
import LeadTime from 'components/elements/LeadTime';
import Price from 'components/elements/Price';
import ProductItemNumber from 'components/elements/ProductItemNumber';
import ProductLink from 'components/elements/ProductLink';
import getAttributeValue from 'components/modules/Product/utils/getAttributeValue';
import ProductListAttributes from 'components/modules/Products/ProductsList/ProductListAttributes';
import ProductListOrderControls from 'components/modules/Products/ProductsList/ProductListOrderControls';
import AddToShoppingListButton from 'components/modules/ShoppingList/AddToShoppingList/Button';
import getAzureStock from 'azure/order/getStock'; // Import getAzureStock function

interface ProductsListItemProps extends Omit<ProductProjection, 'key'> {
  // we use `productKey`, because we cannot use `key` as a prop name.
  // @see https://reactjs.org/warnings/special-props.html
  productKey: Product['key'];
}

/**
 * Used for E2E tests.
 */
const TAG = 'ProductsListItem';

const styles = {
  inStockDot: {
    bg: 'primary.green',
    boxSize: '0.5rem',
    borderRadius: 'full',
    mr: '2', // Adjust margin-right to align text
  },
  outOfStockDot: {
    bg: 'red.500',
    boxSize: '0.5rem',
    borderRadius: 'full',
    mr: '2', // Adjust margin-right to align text
  },
};

const ProductsListItem: FunctionComponent<ProductsListItemProps> = ({ id, productKey, masterVariant, categories }) => {
  const { t } = useTranslation('product');

  const { erpUnitOfMeasure, erpMinQuantity, erpMaxQuantity, erpWarehouse, erpFacility } =
    masterVariant?.price?.custom?.fields || {};

  const [quantity, setQuantity] = useState(erpMinQuantity);

  const warehouseId = erpWarehouse;
  const itemNumber = masterVariant.key;

  // Fetch stock data using getAzureStock
  const stockResponse = useQuery(
    ['getAzureStock', warehouseId, itemNumber],
    () => getAzureStock({ queryKey: ['getAzureStock', warehouseId as string, itemNumber as string] }),
    {
      enabled: !!warehouseId && !!itemNumber, // Enabled only if both warehouseId and itemNumber are defined
    },
  );

  // Determine stock availability
  const isStockAvailable = stockResponse.data?.stockAvailable;

  return (
    <Stack
      as={ListItem}
      direction="row"
      flexWrap="wrap"
      justifyContent="space-between"
      alignItems="flex-start"
      data-product-id={id}
      data-testid={TAG}
      border="1px"
      borderColor="gray.300"
      padding="4"
      spacing={{ base: 0, md: 2 }}
    >
      <Box minW="220px">
        <ProductLink productKey={productKey} masterVariant={masterVariant} categories={categories} isLarge>
          <Box mt="2">
            <Flex flexWrap="wrap">
              <DT>{t('components.header.kanthalItemNumber')}</DT>
              <DD ml="1">{getAttributeValue('erpItemNumber', masterVariant.attributes)}</DD>
            </Flex>
            <ProductItemNumber price={masterVariant?.price} />
            <ProductListAttributes masterVariant={masterVariant} />
          </Box>
        </ProductLink>
      </Box>
      <Flex
        alignItems="flex-end"
        flexDirection={{ base: 'row-reverse', lg: 'row' }}
        w={{ base: 'full', sm: 'max-content' }}
      >
        <AddToShoppingListButton
          productId={id}
          variantId={masterVariant.id}
          quantity={1}
          erpFacility={erpFacility}
          erpWarehouse={erpWarehouse}
          mb="2"
        />
        <Box w="full">
          {/* Stock Availability Section */}
          <Flex maxW="220px" align="center">
            <Box {...(isStockAvailable ? styles.inStockDot : styles.outOfStockDot)} />
            <Text fontWeight="bold" color="black" mr="2">Stock:</Text>
            <Text color="black">
              {isStockAvailable ? t('components.detail.inStock') : t('components.detail.outOfStock')}
            </Text>
          </Flex>

          {/* Lead Time Section */}
          <Flex maxW="220px" mt="2">
            <DT>{t('components.detail.leadTime')}</DT>
            <DD ml="1">
              <LeadTime masterVariant={masterVariant} />
            </DD>
          </Flex>

          {/* Price and Order Controls */}
          <Flex maxW="220px" fontSize="lg" align="center" justifyContent={{ base: 'space-between', sm: 'flex-start' }}>
            <Text fontWeight="bold" mr="2">
              {t('components.detail.yourUnitPrice')}
            </Text>
            <Price price={masterVariant.price} quantity={quantity} />
          </Flex>
          <Flex mt="2" w="full">
            <ProductListOrderControls
              productId={id}
              variantId={masterVariant.id}
              minQuantity={erpMinQuantity}
              maxQuantity={erpMaxQuantity}
              quantity={quantity}
              setQuantity={setQuantity}
              erpWarehouse={erpWarehouse}
              erpFacility={erpFacility}
              unit={erpUnitOfMeasure}
            />
          </Flex>
        </Box>
      </Flex>
    </Stack>
  );
};

ProductsListItem.displayName = TAG;

export default ProductsListItem;
